<template>
    <v-container class="pa-0" fluid>
        <page-title :heading="$t('erp.lang_Zusatzstoffe')"
                    :subheading="$t('erp.lang_Zusatzstoffe')"
                    :icon="icon"
                    show-add-button
                    :permission-add="this.$store.getters['permissions/checkPermission']('additives')"
        ></page-title>
        <div class="app-main__inner">
            <additives-component/>
        </div>
    </v-container>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import AdditivesComponent from "../../../../components/erp/settings/additive/AdditivesComponent";

    export default {
        components: {
            PageTitle,
            AdditivesComponent
        },

        data: () => ({
            icon: 'mdi mdi-alert-plus-outline icon-gradient bg-tempting-azure',
        })
    }
</script>