<template>
        <v-container fluid>
            <v-card elevation="6" outlined>
                <v-card-text class="ma-0 pa-0">
                    <Datatable ref="additiveDatatable"
                               :api-endpoint="ENDPOINTS.DATATABLES.ERP.ADDITIVE"
                               :datatable-headers="datatableHeaders"
                               :excel-columns="excelColumns"
                               excel-file-name="Additives"
                               @editEntry="entryEdit"
                               @deleteEntry="entryDelete"
                               @deleteEntries="entriesDelete"
                               show-delete-buttons
                               show-edit-buttons
                               :permissionDelete="this.$store.getters['permissions/checkPermission']('additives')"
                               :permissionEdit="this.$store.getters['permissions/checkPermission']('additives')"
                    />
                </v-card-text>
            </v-card>
        </v-container>
</template>

<style>
</style>

<script>
    import {ENDPOINTS} from '../../../../config'
    import {Events} from "../../../../plugins/events";
    import Datatable from "../../../datatable/Datatable";
    import mixin from '../../../../mixins/KeyboardMixIns'

    export default {
        components: {
            Datatable
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                id: null,
                text: "",
                additiveAdd: "",
            }
        },
        computed: {
            // --- Datatable ---
            datatableHeaders() {
                return [
                    {
                    text: 'ID',
                    align: 'left',
                    value: 'id',
                    width: 80,
                    },
                    {text: this.$t('erp.lang_Zusatzstoffe'), value: "name",sort: "asc"},
                ];
            },
            excelColumns() {
                return [
                    {
                    label: "ID",
                    field: "id",
                    },
                    {
                    label: this.$t('erp.lang_Zusatzstoffe'),
                    field: "name",
                    },
                ];
            },
        },
        methods: {
            deleteData: function (idsToDelete = []) {
                let self = this;
                this.$swal({
                    title: this.$t('erp.lang_deleteAdditive'),
                    text: this.$t('erp.lang_deleteAdditiveText'),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post(ENDPOINTS.ERP.ADDITIVE.DELETE, {
                            editAdditiveID: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('erp.lang_additiveDeleted'),
                                    color: "success"
                                });
                                self.$refs.additiveDatatable.getDataFromApi();
                                self.$refs.additiveDatatable.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading,
                });
            },
            entryEdit(entry) {
                this.$router.push({name: 'erp.settings.additive.edit', params: {id: parseInt(entry.id)}});
            },
            entryDelete(entry) {
                this.id = entry.id;
                this.deleteData();
            },
            entriesDelete(entries) {
                this.deleteData(entries);
            },

        },
    }
</script>